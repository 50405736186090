import { useLazyQuery } from '@apollo/client';
import {
  GetNotificationCountQuery,
  GetNotificationCountQueryVariables,
  GetNotificationCountDocument,
} from '../../generated/graphql';
import { useCallback, useEffect, useMemo } from 'react';

export const useNotificationCount = () => {

  const [getNotificationCount, { data, loading }] = useLazyQuery
  <GetNotificationCountQuery, GetNotificationCountQueryVariables>(GetNotificationCountDocument, {
    variables: {
      read: false,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getNotificationCount();
  }, [getNotificationCount]);

  const reloadNotificationCount = useCallback(() => {
    getNotificationCount();
  },[getNotificationCount]);


  return useMemo(() => ({
    counters: data?.getNotificationCount || null,
    loading,
    reloadNotificationCount,
  }), [
    data,
    loading,
    reloadNotificationCount,
  ])
}