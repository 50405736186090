export function splitStringIntoChunks(str: string, size?: number) {
  if (!size || str.length <= size) return [str];

  const chunks: string[] = [];
  let i = 0;

  while (i < str.length) {
    let sliced = str.substring(i, i += size);
    const wordWasSliced = sliced[sliced.length - 1] !== ' '
      && i < str.length
      && str[i] !== ' ';

    if (wordWasSliced) {
      sliced = `${sliced}-`;
    }
    chunks.push(sliced);
  }
  return chunks;
}

export function splitASLDescription (description: string) {
  const descriptionParts = description.split('-');
  return ({
    regionCode: descriptionParts[0],
    regionName: descriptionParts[1],
    localHealthAuthority: descriptionParts[2],
    name: descriptionParts[3],
    address: descriptionParts[4],
    cap: descriptionParts[5],
    city: descriptionParts[6],
    telephone: `${descriptionParts[7]} ${descriptionParts[8]}`,
  })
}